import React, {useRef} from "react"
import {Helmet} from "react-helmet"
import {Link} from "gatsby"
import Layout from "../components/layout"
import FadeExample from '../components/slider'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'

import "../styles/styles.scss"

import homeStyles from "./home.module.scss"

import "intersection-observer"
import IsVisible from "react-is-visible"

import imageHome from "../images/pensjonat-glowne-photo-smoke.jpg"
import imgGarden from "../images/ogrod-stolik-male.jpg"
import imgPool from "../images/basen-male.jpg"
import imgAltana from "../images/altana-male.jpg"
import sarnaBg from "../images/sarna-bg.jpg"

import treeRight from "../images/tree-right.jpg"
import leftCorner from "../images/decorative-corner-left.svg"
import rightCorner from "../images/decorative-corner-right.svg"
import decorativeTree from "../images/decorative-tree.svg"
import deerSvg from "../images/deer.svg"

import ReactPlayer from 'react-player/lazy'
import videoDeer from "../video/sarna.mp4"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faUtensils, faWifi, faTv, faShower, faTree, faSwimmingPool, faCheck, faHome} from '@fortawesome/free-solid-svg-icons'
import { faFreeCodeCamp } from '@fortawesome/free-brands-svg-icons'

const HomePage = () => {
    const TITLE = 'Pensjonat U Wierzby Pod Lasem | Agroturystyka Wielkopolska | Noclegi';
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <html lang="pl" />
          <title>{ TITLE }</title>
          <meta name="description" content="Gospodarstwo agroturystyczne - Pensjonat U Wierzby Pod Lasem | Nowy Tomyśl | Wielkopolska. Agroturystyka i komfortowe noclegi w atrakcyjnych cenach." />
          <link rel="canonical" href="https://www.uwierzby.pl" />
          <link rel="preconnect" href="https://fonts.gstatic.com"/>
          <link rel="preload" href="https://fonts.googleapis.com/css2?family=Charm&family=Cormorant+SC:wght@300&family=Quicksand&display=swap" as="style"></link>
      </Helmet>
      <Layout>
        <section className={homeStyles.sliderSection}>
          <FadeExample/>
                <button className={homeStyles.toBottom} onClick={executeScroll} aria-label="scroll down">
                  <svg className={homeStyles.arrows}>
                    <path className={homeStyles.a1} d="M0 0 L30 32 L60 0"></path>
                    <path className={homeStyles.a2} d="M0 20 L30 52 L60 20"></path>
                    <path className={homeStyles.a3} d="M0 40 L30 72 L60 40"></path>
                  </svg>
                </button>
        </section>
        <section className={homeStyles.underSlider} ref={myRef}>
          <IsVisible once>
          {isVisible =>
            <div className={`${homeStyles.bodySection} ${isVisible ? homeStyles.visible : homeStyles.notVisible}`}>
              <div className={homeStyles.bodySection_Title}>
                <h1 className={homeStyles.sectionTitle___underslider}>pensjonat<br/>"U&nbsp;Wierzby Pod&nbsp;Lasem"</h1>
                <h2 className={homeStyles.sectionSubTitleWide}>Gospodarstwo Agroturystyczne</h2>
              </div>
              <div className={homeStyles.bodySectionText}>
                <img className={homeStyles.deer} src={deerSvg} loading="lazy" alt="deer in forest"></img>
                <div className={`${homeStyles.bodySectionPar} text-justify`}>
                  <p>Zapraszamy Państwa do naszego domu we wsi <span>Przyłęk</span>, otoczonej lasami i łąkami.</p>
                  <p>Do jednego z najstarszych domostw <span>poolęderskich</span>, położonych w okolicy <span>Nowego Tomyśla</span> - miejscowości leżącej w zachodniej części województwa Wielkopolskiego i słynącej w całym kraju jako <span>miasto wikliniarzy</span>.</p>
                  <div className={homeStyles.moreButtonWrapper}>
                    <Link className={homeStyles.moreButton}
                          activeClassName={homeStyles.activeMoreButton}
                          to="/o-nas/">
                      <div className="button">
                        <span className="noselect">poznaj pensjonat</span>
                      </div>
                    </Link>
                  </div>
                </div>
                <img className={homeStyles.treeRight} src={treeRight} loading="lazy" alt="decoration right"></img>
              </div>
            </div>
          }
          </IsVisible>
        </section>
        <div className={homeStyles.triangleWrapper}>
          <div className={homeStyles.triangleDown}></div>    
        </div>
        <div className={homeStyles.parallax}>
          <IsVisible once>
            {isVisible =>
              <div className={`${homeStyles.verticalLineWrapper} ${isVisible ? homeStyles.visible : homeStyles.notVisible}`}>
                <div className={homeStyles.verticalLine}></div>
              </div>
            }
          </IsVisible>
        </div>   
        <section className={homeStyles.amenitiesSection}>
          
          <IsVisible once>
            {isVisible =>
              <div className={`${homeStyles.bodySectionWide} ${isVisible ? homeStyles.visible : homeStyles.notVisible}`}>
              <div className={homeStyles.titleWrapper}>
                <div className={homeStyles.bigLetter}><p>U</p></div>
                <h2 className={homeStyles.sectionTitleWide}>udogodnienia</h2>
                <h3 className={homeStyles.sectionSubTitleWide}>dla Państwa komfortu</h3>
              </div>
                <Container fluid className={homeStyles.amenitiesContainer}>
                  <Row className={homeStyles.amenitiesContainer_Row}>
                    <Col sm={4} className={homeStyles.amenitiesCol}>
                      <div className={homeStyles.amenities}>
                        <h4>
                          <span className={homeStyles.amenitiesIconBg}>
                            <FontAwesomeIcon icon={faBed} className={homeStyles.icon}/>
                          </span>Wygodne Pokoje
                        </h4>
                      </div>
                      <div className={homeStyles.amenitiesDes}>
                        <p>Oferujemy wygodne pokoje 1, 2, 3, 4, 5-osobowe, a także komfortowy apartament.</p>
                      </div>
                    </Col>
                    <Col sm={4} className={homeStyles.amenitiesCol}>
                      <div className={homeStyles.amenities}>
                        <h4>
                          <span className={homeStyles.amenitiesIconBg}>
                            <FontAwesomeIcon icon={faFreeCodeCamp}/>
                          </span>Sala Kominkowa
                        </h4>
                      </div>
                      <div className={homeStyles.amenitiesDes}>
                        <p>Do dyspozycji Gości oprócz pokoi, pozostawiamy duży salon z kominkiem.</p>
                      </div>
                    </Col>
                    <Col sm={4} className={homeStyles.amenitiesCol}>
                      <div className={homeStyles.amenities}>
                        <h4>
                          <span className={homeStyles.amenitiesIconBg}>
                            <FontAwesomeIcon icon={faUtensils}/>
                          </span>Kuchnia
                        </h4>
                      </div>
                      <div className={homeStyles.amenitiesDes}>
                        <p>A także w pełni wyposażoną, ogólnodstępną kuchnię.</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className={homeStyles.amenitiesContainer_Row}>
                    <Col sm={4} className={homeStyles.amenitiesCol}>
                      <div className={homeStyles.amenities}>
                        <h4>
                          <span className={homeStyles.amenitiesIconBg}>
                            <FontAwesomeIcon icon={faWifi} className={homeStyles.icon}/>
                          </span>Bezpłatne WiFi
                        </h4>
                      </div>
                      <div className={homeStyles.amenitiesDes}>
                        <p>Dla Państwa komfortu, bezpłante WiFi dostępne jest w całym obiekcie.</p>
                      </div>
                    </Col>
                    <Col sm={4} className={homeStyles.amenitiesCol}>
                      <div className={homeStyles.amenities}>
                        <h4>
                          <span className={homeStyles.amenitiesIconBg}>
                            <FontAwesomeIcon icon={faTv} className={homeStyles.icon}/>
                          </span>TV
                        </h4>
                      </div>
                      <div className={homeStyles.amenitiesDes}>
                        <p>Każdy pokój wyposażony jest we własny telewizor</p>
                      </div>
                    </Col>
                    <Col sm={4} className={homeStyles.amenitiesCol}>
                      <div className={homeStyles.amenities}>
                        <h4>
                          <span className={homeStyles.amenitiesIconBg}>
                            <FontAwesomeIcon icon={faShower}/>
                          </span>Łazienki
                        </h4>
                      </div>
                      <div className={homeStyles.amenitiesDes}>
                        <p>Wszystkie pokoje posiadają łazienki</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            }
          </IsVisible>
        </section>
        <div className={homeStyles.fillDiv}></div>
        <div className={homeStyles.amenitiesWave}>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 230 100" preserveAspectRatio="none">
            <g
              transform="translate(-64.461788,61.192713)">
              <path className={homeStyles.wave}
                d="m 180.89654,-38.66954 v -0.001 h 116.43475 v -9.184969 c -18.19787,7.50582 -34.41672,5.201738 -49.78042,-0.347782 -23.15438,-8.363814 -43.55464,-12.315882 -66.65433,-12.904122 v 10e-4 c -23.09969,0.58824 -43.49995,4.540303 -66.65433,12.904121 -15.363703,5.549512 -31.582555,7.853604 -49.780422,0.347783 V -61.192713 H 297.37108 v 22.523173 z m -66.65433,-9.532752 66.65433,-0.466121 v 10e-4 z m 66.65433,-0.466121 v -10e-4 l 66.65433,0.466122 z"
              />
            </g>
          </svg>
        </div>
        <div className={homeStyles.parallaxSecond}>
          <p className={homeStyles.parallaxSecondPar}>Czekają na Państwa: przyjazny dom, ciekawi ludzie i dobra zabawa.</p>
        </div>
        <section className={homeStyles.attractionsSection}>
          <img className={homeStyles.leftCorner} src={leftCorner} loading="lazy" alt="top left decorative corner"></img>
          <IsVisible once>
            {isVisible =>
            <div className={`${homeStyles.bodySectionWide} ${isVisible ? homeStyles.visible : homeStyles.notVisible}`}>
              <div className={homeStyles.titleWrapper}>
                <div className={homeStyles.bigLetter}><p>A</p></div>
                <h2 className={homeStyles.sectionTitleWide}>atrakcje</h2>
                <h3 className={homeStyles.sectionSubTitleWide}>do Państwa dyspozycji</h3>
              </div>
              <div className={homeStyles.attractionsBg}><div></div></div>
                <Container fluid className={homeStyles.containerCenter}>
                  <Row className={`justify-content-md-center ${homeStyles.attractionsRow}`}>
                    <Col sm={4} className={homeStyles.attractionsCol}>
                        <div className={homeStyles.attractionsCardBorder}></div>
                        <div className={homeStyles.attractionsCard}></div>
                          <div className={homeStyles.attraction}>
                            <h4>
                              <span className={homeStyles.amenitiesIconBg}>
                                <FontAwesomeIcon icon={faTree}/>
                              </span>Ogród
                            </h4>
                          </div>
                          <div className={homeStyles.attractionDes}>
                            <div className={homeStyles.attractionImgBox}>
                              <img className={homeStyles.attractionImg} src={imgGarden} loading="lazy" alt="ogród"></img>
                            </div>
                            <p>Ciekawie zagospodarowany <span>ogród</span> umożliwia wypoczynek wśród zieleni, a także spędzenie miłych chwil przy <span>ognisku</span> lub <span>grillu</span>, w gronie rodziny, przyjaciół i znajomych.</p>
                          </div>
                    </Col>
                    <Col sm={4} className={homeStyles.attractionsCol}>
                      <div className={homeStyles.attractionsCardBorder}></div>
                      <div className={homeStyles.attractionsCard}></div>
                      <div className={homeStyles.attraction}>
                        <h4>
                          <span className={homeStyles.amenitiesIconBg}>
                            <FontAwesomeIcon icon={faSwimmingPool}/>
                          </span>Basen
                        </h4>
                      </div>
                      <div className={homeStyles.attractionDes}>
                        <div className={homeStyles.attractionImgBox}>
                          <img className={homeStyles.attractionImg} src={imgPool} loading="lazy" alt="basen"></img>
                        </div>
                        <p>Znajdą Państwo również u nas miejsce na aktywne spędzenie czasu na <span>boisku</span> lub przy <span>basenie</span>.</p>
                      </div>
                    </Col>
                    <Col sm={4} className={homeStyles.attractionsCol}>
                      <div className={homeStyles.attractionsCardBorder}></div>
                      <div className={homeStyles.attractionsCard}></div>
                      <div className={homeStyles.attraction}>
                        <h4>
                          <span className={homeStyles.amenitiesIconBg}>
                            <FontAwesomeIcon icon={faHome}/>
                          </span>Altana
                        </h4>
                      </div>
                      <div className={homeStyles.attractionDes}>
                        <div className={homeStyles.attractionImgBox}>
                          <img className={homeStyles.attractionImg} src={imgAltana} loading="lazy" alt="altana z grillem"></img>
                        </div>
                        <p>Na terenie ogrodu znajduje się także duża <span>altana z grillem</span>, w której można spokojnie odpocząć i miło spędzić czas nawet w niepogodę.</p>
                      </div>
                    </Col>
                  </Row>
                  <div className={homeStyles.moreButtonWrapper}>
                  <Link className={homeStyles.moreButton}
                        activeClassName={homeStyles.activeMoreButton}
                        to="/oferta/">
                    <div className="button">
                      <span className="noselect">pełna oferta</span>
                    </div>
                  </Link>
                </div>
                </Container>
              </div>
            }
          </IsVisible>
          <img className={homeStyles.rightCorner} src={rightCorner} loading="lazy" alt="bottom right decorative corner"></img>
        </section>
        <section className={homeStyles.opinionsSection}>
          <IsVisible once>
            {isVisible =>
              <div className={`${homeStyles.bodySectionWide} ${isVisible ? homeStyles.visible : homeStyles.notVisible}`}>
                <Container fluid className={`p-0 m-0 ${homeStyles.containerCenter}`}>
                  <div className={homeStyles.zaproszenie}>
                    <img className={homeStyles.decorativeTree} src={decorativeTree} alt="decorative tree"></img>
                    <p>Serdecznie dziękujemy Państwu za tak pozytywne opinie. Będziemy nadal dokładać wszelkich starań, aby Państwa pobyt u nas pozostawił miłe i niezapomniane wrażenia z wypoczynku.</p> 
                    <p>Zapraszamy do nas ponownie.</p>
                    <div className="sep-clear"></div>
                  </div>
                  <div className={homeStyles.playerWrapper}>
                    <div className={homeStyles.videoWrapper}>
                    <div className={homeStyles.opinionsWave}>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 100" preserveAspectRatio="none">
                          <g
                            transform="translate(-111.46073,108.19165)">
                              <path className={homeStyles.verticalWave}
                                d="M 133.8976,8.2430995 C 133.30936,-14.856589 129.3573,-35.256848 120.99348,-58.411228 L 121.4596,8.2430995 Z M 120.99348,-58.411228 c -5.54951,-15.363706 -7.85361,-31.582555 -0.34778,-49.780422 h -9.18497 V 8.2430995 h 9.99784 z" />
                          </g>
                        </svg>
                      </div>
                      <ReactPlayer
                        className = 'react-player'
                        url = {videoDeer}
                        playing = {true}
                        loop = {true}
                        muted = {true}
                        width = "100%"
                        height = "auto"
                      />
                    </div>
                    <img src={sarnaBg} className={homeStyles.videoBg} loading="lazy" alt="video tło"/>
                    <Carousel className={homeStyles.opinionCarousel}
                      bsPrefix = 'carousel'
                      pause = 'hover'
                      interval = {10000}
                      slide = {false}
                      fade  = {true}
                  >
                      <Carousel.Item className={homeStyles.opinionCarouselItem} bsPrefix = 'carousel-item'>
                        <Carousel.Caption className={homeStyles.opinionCarouselCaption} bsPrefix = 'carousel-caption'>
                          <p className={homeStyles.opinionCarouselCaptionQuote}>"</p>
                          <p className={homeStyles.opinionCarouselCaptionTitle}>Miła Obsługa</p>
                          <p className={homeStyles.opinionCarouselCaptionBody}>"Bardzo spokojna osadka mila obsługa pani Barbary oraz jej męża Januszka .spędziliśmy mile chwile których nie zapomnimy .będziemy na pewno powracać w te spokojne miejsce polecamy ta przepiękna cisze której brak w miastach pozdrawiamy oraz spokojnych świat życzą Szczurowie."</p>
                          <p className={homeStyles.opinionCarouselCaptionName}>Antosia Szczur</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item className={homeStyles.opinionCarouselItem} bsPrefix = 'carousel-item'>
                        <Carousel.Caption className={homeStyles.opinionCarouselCaption} bsPrefix = 'carousel-caption'>
                          <p className={homeStyles.opinionCarouselCaptionQuote}>"</p>
                          <p className={homeStyles.opinionCarouselCaptionTitle}>Dom z duszą</p>
                          <p className={homeStyles.opinionCarouselCaptionBody}>"Dom z duszą,ma ponad 200 lat i długo jeszcze będzie gościł ludzi. Czysto, miła atmosfera, przyjemnie :) Cisza i spokój, warto tutaj w delegacji odpocząć POLECAMY :)) "</p>
                          <p className={homeStyles.opinionCarouselCaptionName}>Beata Michalak</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item className={homeStyles.opinionCarouselItem} bsPrefix = 'carousel-item'>
                        <Carousel.Caption className={homeStyles.opinionCarouselCaption} bsPrefix = 'carousel-caption'>
                          <p className={homeStyles.opinionCarouselCaptionQuote}>"</p>
                          <p className={homeStyles.opinionCarouselCaptionTitle}>Super Miejsce</p>
                          <p className={homeStyles.opinionCarouselCaptionBody}>"Super miejsce. Byłem dwa razy na krótko, i dobrze wspominam."</p>
                          <p className={homeStyles.opinionCarouselCaptionName}>Piotr Kujawa</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item className={homeStyles.opinionCarouselItem} bsPrefix = 'carousel-item'>
                        <Carousel.Caption className={homeStyles.opinionCarouselCaption} bsPrefix = 'carousel-caption'>
                          <p className={homeStyles.opinionCarouselCaptionQuote}>"</p>
                          <p className={homeStyles.opinionCarouselCaptionTitle}>Piękny stary dom</p>
                          <p className={homeStyles.opinionCarouselCaptionBody}>"Piękny stary dom położony w urokliwym otoczeniu z życzliwym i radosnymi gospodarzami. Wnętrze domu stylowo urządzone, w pełni wyposażona kuchnia, pościel pachnąca świeżą łąką i fantastyczna cisza wokół. Gorąco polecam"</p>
                          <p className={homeStyles.opinionCarouselCaptionName}>Grzegorz</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item className={homeStyles.opinionCarouselItem} bsPrefix = 'carousel-item'>
                        <Carousel.Caption className={homeStyles.opinionCarouselCaption} bsPrefix = 'carousel-caption'>
                          <p className={homeStyles.opinionCarouselCaptionQuote}>"</p>
                          <p className={homeStyles.opinionCarouselCaptionTitle}>Fantastyczna okolica</p>
                          <p className={homeStyles.opinionCarouselCaptionBody}>"Fantastyczna OKOLICA, urokliwy DOM, sympatyczni GOSPODARZE"</p>
                          <p className={homeStyles.opinionCarouselCaptionName}>Elwira</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </Container>
              </div>
            }
          </IsVisible>
        </section>
        <section className={homeStyles.atutySection}>
          <IsVisible once>
            {isVisible =>
              <div className={`${homeStyles.bodySectionWide} ${isVisible ? homeStyles.visible : homeStyles.notVisible}`}>
                <div className={homeStyles.titleWrapper}>
                  <div className={homeStyles.bigLetter}><p>N</p></div>
                  <h2 className={homeStyles.sectionTitleWide}>nasze atuty</h2>
                  <div className="sep-clear"></div>
                </div>
                  <Container fluid className={`p-0 ${homeStyles.atutyContainer}`}>
                    <Col className={homeStyles.atutyCol}>
                      <Row>
                        <p><span><FontAwesomeIcon className={homeStyles.iconCheck} icon={faCheck}/></span>Położenie w otoczeniu natury</p>
                        <p><span><FontAwesomeIcon className={homeStyles.iconCheck} icon={faCheck}/></span>Komfortowe i czyste pokoje z łazienkami</p>
                        <p><span><FontAwesomeIcon className={homeStyles.iconCheck} icon={faCheck}/></span>Atrakcje dla dzieci</p>
                      </Row>
                    </Col>
                    <Col className={homeStyles.atutyCol}>
                      <Row>
                        <p><span><FontAwesomeIcon className={homeStyles.iconCheck} icon={faCheck}/></span>Liczne udogodnienia</p>
                        <p><span><FontAwesomeIcon className={homeStyles.iconCheck} icon={faCheck}/></span>Przyjazna obsługa</p>
                        <p><span><FontAwesomeIcon className={homeStyles.iconCheck} icon={faCheck}/></span>Niska cena.</p>
                      </Row>
                    </Col>
                  </Container>
              </div>
            }
          </IsVisible>
          <picture>
                <source 
                  srcSet={`
                    ${require('../images/pensjonat-glowne-photo-smoke.webp')},
                    `}>
                </source>
                <img className={homeStyles.mainPhoto} src={imageHome} loading="lazy" alt="Penjonat U Wierzy Pod Lasem"></img>
          </picture>
        </section>
    </Layout>
    </>
  );
};

export default HomePage
