import React from 'react'

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Fade } from '../../node_modules/react-slideshow-image/dist/react-slideshow-image.min'
import '../../node_modules/react-slideshow-image/dist/styles.css'
import { FontAwesomeIcon } from '../../node_modules/@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight } from '../../node_modules/@fortawesome/free-solid-svg-icons'
import Logo from './logo'
import sliderStyles from './slider.module.scss'


const FadeExample = () => {
  const fadeProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: true,
    pauseOnHover: false,
    prevArrow: <div className={`${sliderStyles.sliderArrow} ${sliderStyles.prevArrow}`}><FontAwesomeIcon icon={faCaretLeft}/></div>,
    nextArrow: <div className={`${sliderStyles.sliderArrow} ${sliderStyles.nextArrow}`}><FontAwesomeIcon icon={faCaretRight}/></div>
  };
  
  const data = useStaticQuery(graphql`
    query {
        landscapeImageOne: file(relativePath: { eq: "slider/sunset_large.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        portraitImageOne: file(
          relativePath: { eq: "slider/sunset_large-portrait.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landscapeImageTwo: file(relativePath: { eq: "slider/deer_large.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        portraitImageTwo: file(
          relativePath: { eq: "slider/deer_large-portrait.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landscapeImageThree: file(relativePath: { eq: "slider/table_large.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        portraitImageThree: file(
          relativePath: { eq: "slider/table_large-portrait.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
  `)
  const sourcesOne = [
      data.landscapeImageOne.childImageSharp.fluid,
      {
        ...data.portraitImageOne.childImageSharp.fluid,
        media: `(orientation: portrait)`,
      }
  ]
  const sourcesTwo = [
      data.landscapeImageTwo.childImageSharp.fluid,
      {
        ...data.portraitImageTwo.childImageSharp.fluid,
        media: `(orientation: portrait)`,
      }
  ]

  const sourcesThree = [
    data.landscapeImageThree.childImageSharp.fluid,
    {
      ...data.portraitImageThree.childImageSharp.fluid,
      media: `(orientation: portrait)`,
    }
  ]

  return (
    <>
      <div className={sliderStyles.sliderLogo}>
        <Logo/>
      </div>
      <Fade {...fadeProperties}>
        <div className={`each-fade ${sliderStyles.eachFade}`}>
          <div className={`image-container ${sliderStyles.imageContainer}`}>
            <div className={sliderStyles.sliderOverlay}></div>
            <Img fluid={sourcesOne} className={sliderStyles.sliderImg} objectFit="cover" objectPosition="center center" alt="slide one"/>
          </div>
          <p>Serdecznie zapraszamy <br/>do malowniczego Przyłęku</p>
        </div>
        <div className={`each-fade ${sliderStyles.eachFade}`}>
          <div className={`image-container ${sliderStyles.imageContainer}`}>
            <div className={sliderStyles.sliderOverlay}></div>
            <Img fluid={sourcesTwo} className={sliderStyles.sliderImg} objectFit="cover" objectPosition="center center" alt="slide two"/>
          </div>
          <p>z majestatyczną i unikalną<br/>florą i fauną</p>
        </div>
        <div className={`each-fade ${sliderStyles.eachFade}`}>
          <div className={`image-container ${sliderStyles.imageContainer}`}>
            <div className={sliderStyles.sliderOverlay}></div>
            <Img fluid={sourcesThree} className={sliderStyles.sliderImg} objectFit="cover" objectPosition="center center" alt="slide three"/>
          </div>
          <p>Będzie nam niezmiernie miło <br/>Państwa gościć</p>
        </div>
      </Fade>
    </>
  );
};
export default FadeExample

